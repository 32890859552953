import { labelForName, Label } from '../helpers'
import { StyledCard, StyledCardTitle, StyledCardDesc } from './styles'

interface L0Colors {
  backgroundColor: string
  borderColor: string
}
interface L0Props {
  labels: Label[]
  colors: L0Colors
  theme?: any
}

export default function L0(props: L0Props) {
  const title = labelForName('title', props.labels)
  const description = labelForName('description', props.labels)

  return (
    <StyledCard customTheme={props.theme} {...props.colors}>
      {title && (
        <StyledCardTitle
          dangerouslySetInnerHTML={{ __html: title }}
          customTheme={props.theme}
        />
      )}
      {description && (
        <StyledCardDesc
          dangerouslySetInnerHTML={{ __html: description }}
          customTheme={props.theme}
        />
      )}
    </StyledCard>
  )
}
