import { Field, Formik } from 'formik'
import React from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import {
  Caption,
  Heading,
  InputFieldContainer,
  StyledCheckBoxLabel,
  StyledColumn,
  StyledFlexRow,
  StyledFlexColumn,
  StyledForm,
  StyledFormButton,
  StyledGrid,
  StyledWrapper,
  LinkButton,
} from '../components/commonStyles'
import Loader from '../components/Loader'
import * as Yup from 'yup'
import { ROUTES } from '../constants'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { authService } from '../services'
import { MESSAGE_STATUS, useMessages } from '../context/messages'
import { getErrorKeys, isLoggedIn } from '../helpers'

interface Props {
  t: TFunction
}

const channelSelection = (state): 'email' | 'phone' | 'both' => {
  if (state?.type === 'email' || state?.type === 'phone') {
    return state?.type
  } else {
    if (state?.otpChannels?.email && state?.otpChannels?.phone) return 'both'
    else return state?.otpChannels?.email ? 'email' : 'phone'
  }
}

const deviceSelection = (channel: string, state) => {
  return channel === 'email'
    ? state?.otpChannels?.email
    : state?.otpChannels?.phone
}

const StyledMfaCaption = styled(Caption)`
  font-size: 16px;
  color: var(--pages-mainContainer-primaryTextColor);
`

const MfaChannel: React.FC<Props> = ({ t }) => {
  const history = useHistory()
  const { state } = useLocation<{
    type?: string
    otpChannels: { email?: string; phone?: string }
  }>()

  const { auth } = authService

  if (auth && isLoggedIn(auth)) {
    history.push(ROUTES.DASHBOARD)
  }

  if (!state) {
    history.push(ROUTES.LOGIN)
  }

  const channel = channelSelection(state)

  const { addToast } = useMessages()

  const validationSchema = Yup.object({
    channel: Yup.string().oneOf(['email', 'phone']),
  })

  const handleSubmit = (
    values: { channel: 'email' | 'phone' },
    {
      setSubmitting,
    }: { setSubmitting: React.Dispatch<React.SetStateAction<boolean>> }
  ) => {
    authService
      .sendOtp(values.channel)
      .then((res) => {
        setSubmitting(false)
        addToast(MESSAGE_STATUS.SUCCESS, t('mfaChannel.204', ''))
        history.push({
          pathname: ROUTES.OTP_CODE,
          state: { channel: values.channel },
        })
      })
      .catch((err) => {
        setSubmitting(false)
        let errorMessage = t('errors.genericErrorMessage', '')
        if (err.response) {
          const injectValues = {}
          const { data } = err.response

          if (data?.details?.unlocksInMins) {
            injectValues['unlocksInMins'] = data?.details?.unlocksInMins
          }

          errorMessage = data
            ? t(getErrorKeys('errors', data), injectValues)
            : t(['mfaChannel.errorMessage', 'errors.genericErrorMessage', ''])
        }

        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
  }

  return (
    <StyledWrapper align="center" margin="125px auto">
      <StyledGrid halign="center">
        <StyledColumn
          size={{ md: 4 / 8, lg: 3 / 12 }}
          halign="center"
          direction="column"
        >
          <Heading
            dangerouslySetInnerHTML={{
              __html: t('mfaChannel.heading', ''),
            }}
          />
          <Caption
            dangerouslySetInnerHTML={{
              __html: t('mfaChannel.caption', ''),
            }}
          />
          <Formik
            initialValues={{ channel: channel }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <StyledForm onSubmit={formik.handleSubmit}>
                <InputFieldContainer>
                  {channel === 'both' ? (
                    <React.Fragment>
                      <StyledMfaCaption
                        dangerouslySetInnerHTML={{
                          __html: t('mfaChannel.channelSelectionText', ''),
                        }}
                      />
                      <div role="group" aria-labelledby="channel-radio-group">
                        <StyledFlexColumn padding="8px" align="start">
                          <StyledFlexRow padding="6px 8px">
                            <Field
                              type="checkbox"
                              name="channel"
                              value="email"
                              style={{
                                accentColor:
                                  'var(--pages-mainContainer-primaryTextColor)',
                              }}
                              onChange={(e) => {
                                formik.setFieldValue('channel', e.target.value)
                              }}
                              checked={formik.values.channel === 'email'}
                            />
                            <StyledCheckBoxLabel fontSize="16px">
                              {t(['mfaChannel.emailLabel', ''], {
                                email: state?.otpChannels?.email,
                              })}
                            </StyledCheckBoxLabel>
                          </StyledFlexRow>
                          <StyledFlexRow padding="6px 8px">
                            <Field
                              type="checkbox"
                              name="channel"
                              value="phone"
                              style={{
                                accentColor:
                                  'var(--pages-mainContainer-primaryTextColor)',
                              }}
                              onChange={(e) => {
                                formik.setFieldValue('channel', e.target.value)
                              }}
                              checked={formik.values.channel === 'phone'}
                            />
                            <StyledCheckBoxLabel fontSize="16px">
                              {t(['mfaChannel.phoneLabel', ''], {
                                phone: state?.otpChannels?.phone,
                              })}
                            </StyledCheckBoxLabel>
                          </StyledFlexRow>
                        </StyledFlexColumn>
                      </div>
                    </React.Fragment>
                  ) : (
                    <StyledMfaCaption
                      dangerouslySetInnerHTML={{
                        __html: t(['mfaChannel.noSelectionText', ''], {
                          device: deviceSelection(channel, state),
                        }),
                      }}
                    />
                  )}
                </InputFieldContainer>

                <div>
                  <StyledFormButton
                    type="submit"
                    id="submit"
                    disabled={!formik.isValid}
                  >
                    {formik.isSubmitting ? (
                      <Loader />
                    ) : (
                      <span>{t('mfaChannel.getOtpButton', '')}</span>
                    )}
                  </StyledFormButton>
                </div>
              </StyledForm>
            )}
          </Formik>

          <Link to={ROUTES.LOGIN}>
            <LinkButton
              id="register-btn"
              className="buttons-linkColor"
              margin="4px 0"
            >
              {t('mfaChannel.backButton', '')}
            </LinkButton>
          </Link>
        </StyledColumn>
      </StyledGrid>
    </StyledWrapper>
  )
}

export default withTranslation()(MfaChannel)
