/**
 * THIS FILE SHOULD BE MOVED OUT OF CONTEXT
 */
import merge from 'ts-deepmerge'
import { parsePipeableExpression } from '../helpers/form'
// Returns content between double curly braces {{}}
const regex = /[^{{]+(?=}\})/g

// TODO Needs refactor
function ActionCodes() {
  let activeCase = ''
  let activeFlowId = ''
  let cases = {}
  let errors = []
  let touchedFields = {}
  let activeflowInstance = ''

  // Used to store all fields that need reset by code
  let resetFields = {}

  const needsReplace = (val: string) => regex.test(val)

  const replaceValue = (
    caseId: string,
    value: string,
    def: string | number = 0
  ) => {
    if (value && typeof value === 'string') {
      const matches = value.match(regex)
      if (matches && matches.length > 0) {
        //const sub = isMathExp(value) ? 0 : ''
        matches.forEach((m) => {
          const obj = parsePipeableExpression(m)
          const [code, field] = obj.variableName.trim().split('.')

          const codeValue =
            (cases[caseId][code] && cases[caseId][code][field]) || def
          const val = obj.format(codeValue)

          // TODO Need to decide what happens when val is empty
          value = value.replace('{{' + m + '}}', val)
        })
      }

      // TODO Examine the approach again
      // We try eval the value in case it is an expression otherwise we return the value itself

      try {
        // eslint-disable-next-line no-eval
        return value ? eval(value) : ''
      } catch (e) {
        return value
      }
    }

    return value
  }

  const addCase = (caseId: string, flowId: string, flowInstanceId: string) => {
    activeCase = caseId
    activeFlowId = flowId
    activeflowInstance = flowInstanceId
    if (!(caseId in cases)) {
      cases[caseId] = {}
    }
  }

  const clearActiveCase = () => {
    activeCase = ''
    activeFlowId = ''
  }

  const addCode = (
    caseId: string,
    code: string,
    field: any,
    value: any = false,
    touched: boolean = false
  ) => {
    if (cases[caseId] && !cases[caseId]?.[code]) {
      cases[caseId][code] = {}
    }

    // If code doesnt exist we add it to actionCodes object
    if (!(code in cases[caseId])) {
      cases[caseId][code] = {}
    }

    // If field is provided we update the corresponding action code
    if (field) {
      cases[caseId][code][field] = value
      if (touched && !touchedFields[field]) {
        touchedFields[field] = true
      }
    }
  }

  const addError = (code: string) => {
    if (errors.indexOf(code) === -1) {
      errors.push(code)
    }
  }

  const removeError = (code: string) => {
    const idx = errors.indexOf(code)
    if (idx > -1) {
      errors.splice(idx, 1)
    }
  }

  const isTouched = (field: string) => {
    return touchedFields[field]
  }

  const getCustomerActions = () => {
    let newObj = Object.assign({}, cases[activeCase])
    if (newObj) {
      Object.keys(newObj).map((key) => {
        newObj[key] = {
          optionCode: key,
          optionData: newObj[key],
        }
        return newObj
      })
    }
    return {
      caseId: activeCase,
      flowId: activeFlowId,
      flowInstanceId: activeflowInstance,
      data: cases[activeCase] ? newObj : [],
      errors,
    }
  }

  const getCustomerActionsByCodes = (codes: string[]) => {
    return {
      caseId: activeCase,
      flowId: activeFlowId,
      flowInstanceId: activeflowInstance,
      data:
        codes.length > 0
          ? codes.map((code) =>
              cases[activeCase][code]
                ? {
                    optionCode: code,
                    optionData: cases[activeCase][code],
                  }
                : {
                    optionCode: code,
                    optionData: {},
                  }
            )
          : [],
    }
  }

  const getActionCodes = (caseId: string, code: string) => {
    return cases[caseId][code]
  }

  const getValue = (caseId: string, code: string, name: string) => {
    const val = cases[caseId][code] && cases[caseId][code][name]
    // eslint-disable-next-line eqeqeq
    if (val || val == 0) {
      return val
    } else {
      return ''
    }
  }

  const clearErrorCodes = () => {
    errors.length = 0
    //errors.forEach((error) => removeError(error))
  }

  const clearCodes = (caseId: string, codes: string[]) => {
    codes &&
      codes.length > 0 &&
      codes.forEach((code) => cases[caseId][code] && delete cases[caseId][code])
  }

  const removeCodesFromCase = (caseId: string, codes: string[]) => {
    codes &&
      codes.length > 0 &&
      codes.forEach((code) => {
        if (cases[caseId][code] in cases[caseId]) {
          delete cases[activeCase][code]
        }
      })
  }

  const resetFieldsByCode = (caseId: string, codes: string[]) => {
    codes &&
      codes.length > 0 &&
      codes.forEach((code) => {
        resetFields[caseId][code] &&
          Object.keys(resetFields[caseId][code]).forEach((field) => {
            cases[caseId][code][field] = ''
          })
      })
  }

  const addResetField = (caseId: string, code: string, field: string) => {
    resetFields = merge(resetFields, { [caseId]: { [code]: [field] } })
  }

  const resetAllFields = () => {
    Object.keys(resetFields).forEach((caseId) => {
      Object.keys(resetFields[caseId]).forEach((code) => {
        resetFields[caseId][code].forEach(
          (field) => (cases[caseId][code][field] = '')
        )
      })
    })
  }

  const clearResetFields = () => {
    Object.keys(resetFields).forEach((caseId) => {
      Object.keys(resetFields[caseId]).forEach((code) => {
        delete resetFields[caseId][code]
      })
    })
  }

  const clearActionCodes = () => {
    activeCase = ''
    activeFlowId = ''
    cases = {}
    errors = []
    touchedFields = {}
    resetFields = {}
  }

  return {
    addCode,
    addCase,
    replaceValue,
    addError,
    removeError,
    getCustomerActions,
    getCustomerActionsByCodes,
    getActionCodes,
    needsReplace,
    getValue,
    clearErrorCodes,
    removeCodesFromCase,
    clearActiveCase,
    isTouched,
    clearCodes,
    resetFieldsByCode,
    addResetField,
    resetAllFields,
    clearResetFields,
    clearActionCodes,
    cases,
  }
}

const actionCodes = ActionCodes()
export default actionCodes
