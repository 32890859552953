import React, { useEffect } from 'react'
import cx from 'classnames'
import styled, { css } from 'styled-components'
import DatePickerField from './DatePickerField'
import CurrencyField from './CurrencyField'
import NumberField from './NumberField'
import actionCodes from '../../context/actionCodes'
import { useMessages } from '../../context/messages'
import { UIService } from '../../services'
import {
  StyledFieldLabel,
  StyledFieldInput,
  StyledFieldDesc,
  StyledFieldError,
  StyledFieldSelect,
  StyledFieldContainer,
  StyledTextTitle,
  StyledTextDesc,
  StyledSelectContainer,
  StyledAutosizeTextarea,
  StyledSelectFieldContainer,
  StyledFieldRadioGroup,
  StyledRadioInput,
  StyledRadioInputWrapper,
} from '../styles'
import { clearValue, formatNumber, isNumber } from '../../helpers/form'
import { getToken, setToken, deletePropertyPath } from '../../helpers'
import { ReactComponent as Arrow } from '../../theme/icons/forward.svg'
import { LIST_TYPES, LOCAL_STORAGE_KEYS } from '../../constants'
import { FormikProps } from 'formik'
import merge from 'ts-deepmerge'

const StyledCheckboxContainer = styled.div`
  display: flex;
  margin: 16px 0 0;
  align-items: center;

  ${StyledFieldInput} {
    margin: 0;
  }

  ${StyledFieldLabel} {
    line-height: 17px;
  }

  ${StyledFieldLabel} {
    margin: 0 0 0 10px;
  }
`

const StyledArrow = styled(Arrow)<{ disabled: boolean }>`
  ${({ disabled, theme }) =>
    disabled
      ? css`
          path {
            stroke: var(--pages-mainContainer-secondaryTextColor);
          }
        `
      : ``}
`

// label = 0
// text = 1
// checkbox = 2
// list = 3
// datetime = 4
// number = 5
// currency = 6

const updateCode = (
  caseId: string,
  code: string,
  name: string,
  val: string | boolean,
  touched: boolean = false
) => {
  actionCodes.addCode(caseId, code, name, val, touched)
}

interface FieldProps {
  id?: string
  hidden?: boolean
  calculated?: boolean
  type?: string
  value?: string | number | readonly string[]
  readOnly?: boolean
  round?: boolean
  label?: string
  description?: string
  options?: any
  formik?: FormikProps<{}>
  name?: string
  code?: string
  shouldCache?: boolean
  caseId?: string
  flowId?: string
  flowInstanceId?: string
  minDate?: string | Date
  maxDate?: string | Date
  excludeHolidays?: boolean
  excludeWeekends?: boolean
  excludedDates?: number[]
  currencySymbol?: string
  style?: string
}

const Field: React.FC<FieldProps> = React.memo((props) => {
  const {
    type,
    label,
    description,
    options,
    formik,
    name,
    code,
    shouldCache,
    caseId,
    flowId,
    flowInstanceId,
    style,
    ...rest
  } = props
  const error = formik.touched[name] && formik.errors[name]
  const formattedName = name

  const updateCodes = (name: string, value: string | boolean) => {
    if (formik.errors[name]) {
      actionCodes.addError(name)
    } else {
      actionCodes.removeError(name)

      for (const key of Object.keys(formik.values)) {
        if (key === name) {
          updateCode(caseId, code, key, value, true)
        } else {
          updateCode(caseId, code, key, formik.values[key])
        }
      }
    }
  }

  const { addToast } = useMessages()

  const updateLocalCache = (
    key: string,
    caseId: string,
    flowId: string,
    code: string,
    flowInstanceId: string,
    name: string,
    value: any
  ) => {
    let cached = getToken(key)

    if (!cached) {
      cached = {}
    }

    cached = merge(cached, {
      [caseId]: {
        [flowId]: { [flowInstanceId]: { [code]: { [name]: value } } },
      },
    })

    setToken(key, cached)
  }

  const clearCacheField = (key, caseId, flowId, flowInstanceId, code, name) => {
    let cached = getToken(key)
    if (!cached?.[caseId]?.[flowId]?.[flowInstanceId]?.[code]?.[name]) {
      return false
    }
    let path = `${caseId}.${flowId}.${flowInstanceId}.${code}.${name}`
    deletePropertyPath(cached, path)

    setToken(key, cached)
    return true
  }

  // On blur we should update actionCodes as some fields might be reference
  // to others.
  const handleCustomBlur = (e) => {
    formik.handleBlur(e)
    const {
      name,
      value,
      checked,
      type,
    }: {
      name: string
      value: string
      checked?: boolean
      type: string
    } = e.target
    if (value.length === 0 && type !== 'checkbox') {
      const cleared = clearCacheField(
        LOCAL_STORAGE_KEYS.CACHE,
        caseId,
        flowId,
        flowInstanceId,
        code,
        name
      )

      if (cleared) {
        const user = getToken(LOCAL_STORAGE_KEYS.USER)
        if (user.cacheConfiguredFlowIds.includes(flowId)) {
          UIService.deleteCustomerCache(
            caseId,
            flowId,
            flowInstanceId,
            code,
            name
          )
            .then((data) => {})
            .catch(() => {
              addToast('error', 'Error caching input')
            })
        }
        return
      }
    }
    const newVal = formatValue(value, type, props.type, props.round, checked)

    if (shouldCache && (newVal || isNumber(newVal)) && type !== 'checkbox') {
      // isNumber handles the case for 0 input
      // set token on local storage
      let payload = {}
      payload[name] = newVal
      updateLocalCache(
        LOCAL_STORAGE_KEYS.CACHE,
        caseId,
        flowId,
        code,
        flowInstanceId,
        name,
        payload[name]
      )

      const user = getToken(LOCAL_STORAGE_KEYS.USER)
      if (user.cacheConfiguredFlowIds.includes(flowId)) {
        UIService.postCustomerCache(
          caseId,
          flowId,
          flowInstanceId,
          code,
          payload
        ).then((data) => {})
      }
    }
    updateCodes(name, newVal)

    formik.validateForm(formik.values).then(() => formik.setFieldTouched(name))
    return null
  }

  useEffect(() => {
    // For calculated fields, update the formik with latest value
    if (formik?.values[name] !== rest?.value && props.readOnly) {
      formik.setFieldValue(name, rest?.value)
    }
  }, [props.readOnly, name, rest.value, formik])

  const handleCustomChange = (e) => {
    formik.handleChange(e)
    const {
      name,
      value,
      checked,
      type,
    }: { name: string; value: string; checked?: boolean; type: string } =
      e.target
    const newVal = formatValue(value, type, props.type, props.round, checked)

    if (shouldCache && type === 'checkbox') {
      // set token on local storage
      let payload = {}
      payload[name] = newVal
      updateLocalCache(
        LOCAL_STORAGE_KEYS.CACHE,
        caseId,
        flowId,
        code,
        flowInstanceId,
        name,
        newVal
      )

      const user = getToken(LOCAL_STORAGE_KEYS.USER)
      if (user.cacheConfiguredFlowIds.includes(flowId)) {
        UIService.postCustomerCache(
          caseId,
          flowId,
          flowInstanceId,
          code,
          payload
        )
      }
    }
    updateCodes(name, newVal)
    formik.validateForm(formik.values).then(() => formik.setFieldTouched(name))
    return null
  }

  const formatValue = (
    value: string,
    fieldType: string,
    type: string,
    round?: boolean,
    checked?: boolean
  ) => {
    let newVal = fieldType === 'checkbox' ? checked : value

    if (type === '5' || type === '6') {
      newVal = value !== '' ? formatNumber(round, clearValue(value)) : ''
    }

    if (type === '4' && value) {
      const newDate = new Date(value)
      newVal = newDate.toISOString()
    }
    return newVal
  }

  const extraClassName = cx({
    hidden: props.hidden,
    calculated: props.calculated,
    error: formik.errors[name],
  })

  const calcDesc = actionCodes.replaceValue(caseId, description, '')

  switch (type) {
    //label
    case '0':
      return (
        <StyledFieldContainer className={extraClassName}>
          {label && (
            <StyledTextTitle
              className="field-title"
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
          {calcDesc && (
            <StyledTextDesc
              className="field-desc"
              dangerouslySetInnerHTML={{ __html: calcDesc }}
            />
          )}
        </StyledFieldContainer>
      )
    //text
    case '1':
      return (
        <StyledFieldContainer className={extraClassName}>
          <StyledFieldLabel htmlFor={formattedName}>{label}</StyledFieldLabel>
          <StyledAutosizeTextarea
            {...rest}
            name={formattedName}
            maxRows={4}
            cacheMeasurements
            error={error}
            onBlur={handleCustomBlur}
            onChange={handleCustomChange}
            disabled={props.readOnly}
          />
          {error && <StyledFieldError resizable>{error}</StyledFieldError>}
          {calcDesc && (
            <StyledFieldDesc dangerouslySetInnerHTML={{ __html: calcDesc }} />
          )}
        </StyledFieldContainer>
      )
    //checkbox
    case '2':
      return (
        <StyledFieldContainer className={extraClassName}>
          <StyledCheckboxContainer>
            <StyledFieldInput
              {...rest}
              name={formattedName}
              type="checkbox"
              error={error}
              onBlur={handleCustomBlur}
              onChange={handleCustomChange}
              disabled={props.readOnly}
              value={props.value}
              defaultChecked={!!props.value}
            />
            <StyledFieldLabel htmlFor={name}>{label}</StyledFieldLabel>
          </StyledCheckboxContainer>
          {error && <StyledFieldError>{error}</StyledFieldError>}
          {calcDesc && (
            <StyledFieldDesc dangerouslySetInnerHTML={{ __html: calcDesc }} />
          )}
        </StyledFieldContainer>
      )
    //list
    case '3':
      const selectValue = options.length === 1 ? options[0]?.key : props.value

      return (
        <StyledSelectFieldContainer className={extraClassName}>
          <StyledFieldLabel htmlFor={formattedName}>{label}</StyledFieldLabel>
          {style === LIST_TYPES.DROPDOWN ? (
            <StyledSelectContainer>
              <StyledFieldSelect
                {...rest}
                name={formattedName}
                error={error}
                onBlur={(e) => handleCustomBlur(e)}
                onChange={(e) => handleCustomChange(e)}
                disabled={props.readOnly}
                value={selectValue}
              >
                <option key="empty-option" value="" />
                {options &&
                  options.map((option) => (
                    <option
                      key={option.key}
                      value={option.key}
                      label={option.value}
                      selected={option.selected}
                    >
                      {option.value}
                    </option>
                  ))}
                <optgroup label=""></optgroup>
              </StyledFieldSelect>
              <StyledArrow disabled={props.readOnly} />
            </StyledSelectContainer>
          ) : (
            <StyledFieldRadioGroup>
              {options?.map((option) => (
                <StyledRadioInputWrapper
                  key={option.key}
                  checked={String(formik.values[formattedName]) === option.key}
                  error={error}
                >
                  <StyledRadioInput
                    {...rest}
                    type="radio"
                    key={option.key}
                    name={formattedName}
                    onBlur={(e) => handleCustomBlur(e)}
                    onChange={(e) => handleCustomChange(e)}
                    disabled={props.readOnly}
                    value={option.key}
                    error={error}
                    checked={
                      String(formik.values[formattedName]) === option.key
                    }
                    isChecked={
                      String(formik.values[formattedName]) === option.key
                    }
                  />
                  {option.value}
                </StyledRadioInputWrapper>
              ))}
            </StyledFieldRadioGroup>
          )}
          {error && <StyledFieldError>{error}</StyledFieldError>}
          {calcDesc && (
            <StyledFieldDesc dangerouslySetInnerHTML={{ __html: calcDesc }} />
          )}
        </StyledSelectFieldContainer>
      )
    //datetime
    case '4':
      return (
        <StyledFieldContainer className={extraClassName}>
          <StyledFieldLabel htmlFor={formattedName} className="field-title">
            {label}
          </StyledFieldLabel>
          <DatePickerField
            {...rest}
            name={formattedName}
            error={error}
            code={code}
            caseId={caseId}
            updateCode={updateCode}
            onBlur={handleCustomBlur}
            onChange={handleCustomChange}
          />
          {error && <StyledFieldError>{error}</StyledFieldError>}
          {calcDesc && (
            <StyledFieldDesc dangerouslySetInnerHTML={{ __html: calcDesc }} />
          )}
        </StyledFieldContainer>
      )
    //number
    case '5':
      return (
        <StyledFieldContainer className={extraClassName}>
          <StyledFieldLabel htmlFor={formattedName}>{label}</StyledFieldLabel>
          <NumberField
            {...rest}
            name={formattedName}
            error={error}
            updateCodes={updateCodes}
            onBlur={handleCustomBlur}
          />
          {error && <StyledFieldError>{error}</StyledFieldError>}
          {calcDesc && (
            <StyledFieldDesc dangerouslySetInnerHTML={{ __html: calcDesc }} />
          )}
        </StyledFieldContainer>
      )
    //currency
    case '6':
      const errorAmount = error ? error.split(' ').pop() : ''
      const notRoundError = error
        ? error.substring(0, error.lastIndexOf(' ')) +
          ' ' +
          parseFloat(errorAmount).toFixed(2)
        : ''

      return (
        <StyledFieldContainer className={extraClassName}>
          <StyledFieldLabel htmlFor={formattedName} className="field-title">
            {label}
          </StyledFieldLabel>
          <CurrencyField
            {...rest}
            name={formattedName}
            error={error}
            updateCodes={updateCodes}
            onBlur={handleCustomBlur}
          />
          {error && (
            <StyledFieldError>
              {props.round || isNaN(errorAmount) ? error : notRoundError}
            </StyledFieldError>
          )}
          {calcDesc && (
            <StyledFieldDesc dangerouslySetInnerHTML={{ __html: calcDesc }} />
          )}
        </StyledFieldContainer>
      )
    //default text field
    default:
      return (
        <StyledFieldContainer className={extraClassName}>
          <StyledFieldLabel htmlFor={formattedName}>{label}</StyledFieldLabel>
          <StyledFieldInput
            {...rest}
            name={formattedName}
            type="string"
            error={error}
            onBlur={handleCustomBlur}
            onChange={handleCustomChange}
            disabled={props.readOnly}
          />
          {error && <StyledFieldError>{error}</StyledFieldError>}
          {calcDesc && (
            <StyledFieldDesc dangerouslySetInnerHTML={{ __html: calcDesc }} />
          )}
        </StyledFieldContainer>
      )
  }
})

export default Field
