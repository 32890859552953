import i18n from '../i18n'
import { authService } from '../services'
import axiosClient from './axiosClient'
import { BASE_URL, DEFAULT_HEADERS } from '../constants'
import { Buffer } from 'buffer'

export interface Button {
  action: {
    caseId: string
    type: string
    url: string
    flowInstanceId?: string
    flowId?: string
    option?: {
      id: string
      steps?: any[]
    }
    labels: Label[]
  }
  text: string
  name: string
  theme?: {
    backgroundColor?: string
    textColor?: string
    borderColor?: string
  }
}

interface Size {
  container: {
    lg?: number
    xs?: number
  }
  title: {
    lg?: number
    xs?: number
  }
  icon: {
    lg?: number
    xs?: number
  }
  main: {
    lg?: number
    xs?: number
  }
  side: {
    lg?: number
    xs?: number
  }
}
export interface Label {
  name: string
  text: string
  theme?: {
    primaryTextColor?: string
  }
}

export const getDeviceIdHeader = (deviceId: string) =>
  deviceId ? { 'X-Device-ID': deviceId } : {}

export const getAuthHeader = (token: string) =>
  token ? { Authorization: `Bearer ${token}` } : {}

export const authAccessHeader = () => {
  const { auth } = authService
  return auth && auth.accessToken ? getAuthHeader(auth.accessToken) : {}
}

export const authRefreshHeader = () => {
  const { auth } = authService
  return auth && auth.refreshToken ? getAuthHeader(auth.refreshToken) : {}
}

export const tempAccessHeader = () => {
  const { temp } = authService
  return temp && temp.accessToken ? getAuthHeader(temp.accessToken) : {}
}

const httpClient = axiosClient(authService)

export const axiosGet = (
  uri: string,
  headers = {},
  params = {},
  baseUrl = BASE_URL
) => {
  return httpClient
    .get(baseUrl + uri, {
      headers: { ...DEFAULT_HEADERS, ...headers },
      params,
    })
    .catch((e) => {
      return Promise.reject(e)
    })
}

export const axiosHead = (uri: string, headers = {}, params = {}) => {
  return httpClient
    .head(BASE_URL + uri, {
      headers: { ...DEFAULT_HEADERS, ...headers },
      params,
    })
    .catch((e) => {
      return Promise.reject(e)
    })
}

// AuthHeader should be last in order to have always the latest accessToken
export const axiosAuthGet = (uri: string, headers = {}, params = {}) => {
  return axiosGet(
    uri,
    { ...headers, ...authAccessHeader() },
    { ...params }
  ).catch((e) => {
    return Promise.reject(e)
  })
}

export const axiosAuthHead = (uri: string, headers = {}, params = {}) => {
  return axiosHead(
    uri,
    { ...headers, ...authAccessHeader() },
    { ...params }
  ).catch((e) => {
    return Promise.reject(e)
  })
}

export const axiosPost = (uri: string, data = {}, headers = {}) => {
  return httpClient.post(BASE_URL + uri, data, {
    headers: { ...DEFAULT_HEADERS, ...headers },
  })
}

export const axiosDelete = (uri: string, headers = {}) => {
  return httpClient.delete(BASE_URL + uri, {
    headers: { ...DEFAULT_HEADERS, ...headers },
  })
}

// AuthHeader should be last in order to have always the latest accessToken
export const axiosAuthPost = (uri: string, data = {}, headers = {}) => {
  return axiosPost(uri, data, { ...headers, ...authAccessHeader() })
}

export const axiosAuthDelete = (uri: string, headers = {}) => {
  return axiosDelete(uri, { ...headers, ...authAccessHeader() })
}

// AuthHeader should be last in order to have always the latest accessToken
export const axiosRefreshAuthPost = (uri: string, data = {}, headers = {}) => {
  return axiosPost(uri, data, { ...headers, ...authRefreshHeader() })
}

export const axiosTempPost = (uri: string, data = {}, headers = {}) => {
  return axiosPost(uri, data, { ...tempAccessHeader(), ...headers })
}

// TODO Add TTL to localstorage
export const setToken = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const getToken = (key: string) => {
  return JSON.parse(localStorage.getItem(key))
}

export const removeToken = (key: string) => {
  localStorage.removeItem(key)
}

export const isLoggedIn = (auth: {
  accessToken: string
  refreshToken: string
}) => {
  const accessToken = auth?.accessToken
  const refreshToken = auth?.refreshToken

  if (!accessToken || !refreshToken) {
    return null
  }

  try {
    const decoded = JSON.parse(
      Buffer.from(refreshToken.split('.')[1], 'base64').toString()
    )

    return decoded?.exp && new Date(decoded?.exp * 1000) > new Date()
  } catch (e) {
    console.log(e)
    return false
  }
}

export const getErrorKeys = (
  nm: string,
  data: { code: number; details: { error: string; type: string } }
) => {
  const errorKeys: string[] = []
  if (data.code) {
    if (data?.details?.type) {
      errorKeys.push(nm + '.' + data.code + '_' + data.details.type)
      errorKeys.push('errors.' + data.code + '_' + data.details.type)
    } else if (data?.details?.error) {
      errorKeys.push(nm + '.' + data.code + '_' + data.details.error)
      errorKeys.push('errors.' + data.code + '_' + data.details.error)
    }
    errorKeys.push(nm + '.' + data.code)
    errorKeys.push('errors.' + data.code)
  }
  return errorKeys
}

export const retry = (
  fn: () => Promise<void>,
  retriesLeft = 5,
  interval = 1000
) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const addTranslations = (data: { [s: string]: any }) => {
  Object.entries(data).forEach(([locale, translations]) => {
    i18n.addResourceBundle(
      //TODO Revert to locale
      'en',
      'translation',
      { ...translations },
      true,
      true
    )
    // TODO Revisit this to find a proper way to rerender translations
    // after addResourceBundle
    i18n.changeLanguage('en')
  })
}

export const deletePropertyPath = (obj: any, path: any) => {
  if (!obj || !path) {
    return
  }
  if (typeof path === 'string') {
    path = path.split('.')
  }
  for (let i = 0; i < path.length - 1; i++) {
    obj = obj[path[i]]
    if (typeof obj === 'undefined') {
      return
    }
  }
  delete obj[path.pop()]
}

export const dateDiff = (date1: Date, date2: Date) => {
  const Difference_In_Time = date1.getTime() - date2.getTime()
  // To calculate the no. of days between two dates
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
  return Difference_In_Days
}

export const labelForName = (name: string, array?: Label[]) => {
  return array ? array.find((item) => item.name === name)?.text : undefined
}

export const buttonForName = (name: string, array: Button[]) => {
  return array ? array.find((item) => item.name === name) : undefined
}

export const themeForText = (text: string, array: any) => {
  return array && text
    ? array.find((item) => item.text === text).theme
    : undefined
}

export const sizes1: Size = {
  container: { lg: 8 / 12 },
  title: { xs: 6 / 8 },
  icon: { xs: 2 / 8 },
  main: { lg: 6 / 8 },
  side: { lg: 2 / 8 },
}

export const sizes2: Size = {
  container: { lg: 6 / 12 },
  title: { xs: 6 / 8 },
  icon: { xs: 2 / 8 },
  main: { xs: 8 / 8 },
  side: { xs: 0 / 8 },
}

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max)
}
