import React from 'react'
import styled from 'styled-components'
import { useMessages } from '../context/messages'
import { useStore } from '../context'
import { setThankYou, setStepLayout } from '../context/actionReducer'
import actionCodes from '../context/actionCodes'
import { UIService } from '../services'
import { getNextStep } from '../helpers/form'
import { StyledOptionsCard } from './styles'
import { Button, ButtonsContainer } from './commonStyles'
import Options from './Options'
import Loader from './Loader'
import { withTranslation } from 'react-i18next'
import { ReactComponent as BackIcon } from '../theme/icons/forward.svg'
import { getToken, setToken, themeForText } from '../helpers'
import { LOCAL_STORAGE_KEYS } from '../constants'
import { deletePropertyPath, labelForName, buttonForName } from '../helpers'

const StyledButton = styled(Button)`
  margin: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.md - 1}px) {
    width: 100%;
    margin-top: 20px;
    min-height: 44px;
  }
`

const StyledBackButton = styled(StyledButton)`
  font-size: 14px;
  color: var(--pages-mainContainer-primaryTextColor);
  background: transparent;
  border: 1px solid transparent;
  text-decoration: underline;
  margin-right: 20px;
  cursor: pointer;
  padding: 8px 15px;
  font-weight: 500;
  &: hover {
    background: transparent;
    color: var(--pages-mainContainer-primaryTextColor);
    border: 1px solid transparent;
  }
`

const StyledTopBackButton = styled(StyledBackButton)`
  margin: 0 0 26px 0;
  padding: 0;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  text-decoration: none;

  @media (max-width: 500px) {
    margin: -10px 0 10px;
  }

  svg {
    margin-left: -10px;
  }

  span {
    padding: 0 8px;
    line-height: 17px;
  }
`

const StyledOptionFooter = styled.div<{
  customTheme?: { primaryTextColor?: string }
}>`
  //margin-top: 30px;
  margin-bottom: 16px;
  flex-basis: 100%;
  width: 100%;

  font-size: 12px;
  font-style: italic;
  margin-left: 24px;

  color: ${(props) =>
    props.customTheme ? props.customTheme.primaryTextColor : 'currentColor'};

  a {
    color: var(--buttons-hypertextLinkColor);
  }
`

const StyledOptionHeader = styled.div<{
  customTheme?: { primaryTextColor?: string }
}>`
  margin-bottom: 16px;
  flex-basis: 100%;
  width: 100%;
  color: ${(props) =>
    props.customTheme ? props.customTheme.primaryTextColor : 'currentColor'};
`

function Steps(props) {
  // The following ref is used to store all form formiks from children
  // components as Dataforms, Ptp forms etc.
  const formikRef = React.useRef({})
  const errorsRef = React.useRef({})

  //TODO revisit steps implementation
  const { steps, startLoading, finishLoading, caseId, t, randomJourneyId } =
    props
  const [, dispatch] = useStore()

  const [step, setStep] = React.useState(steps[0]?.id || 0)
  // We use this array to store previous steps as a FIFO queue. Thus the previous step
  // will be always the first item of the array.
  // TODO useRef for prevSteps instead of useState - no need for rerender
  const [prevSteps, setPrevSteps] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  const { addToast } = useMessages()

  const { items, labels, buttons, analyticsTag } = steps.find(
    (st) => st.id === step
  )

  const header = labelForName('header', labels)
  const footer = labelForName('footer', labels)
  const backButton = buttonForName('backButton', buttons)
  const nextButton = buttonForName('nextButton', buttons)

  // Send analytics tag in case that there are no items
  React.useEffect(() => {
    let isMounted = true
    if (analyticsTag && isMounted && items.length === 0) {
      UIService.postPageView(analyticsTag, randomJourneyId)
    }
    return () => {
      isMounted = false
    }
  }, [analyticsTag, randomJourneyId, items])

  // We post all history items
  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!nextButton) {
      items.forEach((item) => {
        if (item.type === 'history') {
          const value = actionCodes.replaceValue(caseId, item.body, '')
          UIService.postUserHistory(value).catch(() =>
            addToast(
              'error',
              t([
                'errors.genericErrorMessage',
                'options.errorUpdatingHistory',
                '',
              ])
            )
          )
        }
      })
      if (!backButton) {
        setToken(LOCAL_STORAGE_KEYS.USER_OPTIONS, null)
      }
    }
  }, [items, addToast, t, nextButton, backButton, caseId])

  // Update title and description for current step
  React.useEffect(
    () => dispatch(setStepLayout({ labels: labels })),
    [dispatch, labels]
  )

  function goNext(nextStep = null) {
    if (!nextStep) {
      addToast(
        'error',
        t([
          'errors.genericErrorMessage',
          'options.nextStepNotConfiguredContactAdmin',
          '',
        ])
      )
      return false
    }

    errorsRef.current = {}
    // Before going to next step we need to see if there is any error in the forms
    // and display them to the user. If the field level, the error is displayed if
    // formik.touched[name] && formik.errors[name]
    Object.entries(formikRef.current).forEach(([c, f]: any) => {
      f.setTouched(f.errors)
      errorsRef.current = { ...errorsRef.current, ...f.errors }
    })

    if (Object.keys(errorsRef.current).length > 0) {
      addToast('error', t(['forms.fixErrors', 'options.fixErrors', '']))
      //window.scrollTo(0, 0)
      const el = document.getElementsByClassName('error')[0]
      el &&
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        })
    } else {
      const { calcNextStep, beforeTransition } = getNextStep(
        caseId,
        nextStep.action?.transitions
      )
      if (!calcNextStep) {
        addToast(
          'error',
          t([
            'errors.genericErrorMessage',
            'options.nextStepNotConfiguredContactAdmin',
            '',
          ])
        )
        return false
      }

      const submitTransition = beforeTransition
        ? beforeTransition.find((item) => item.type === 'submit')
        : null

      if (
        submitTransition &&
        submitTransition.codes &&
        submitTransition.codes.length > 0
      ) {
        const { caseId, flowId, data, flowInstanceId } =
          actionCodes.getCustomerActionsByCodes(submitTransition.codes)

        startLoading()
        setIsLoading(true)

        UIService.postUserOptions({
          data,
          caseId,
          flowId,
        })
          .then(() => {
            finishLoading()
            addToast('success', t('forms.success', ''))
            let crnCache = getToken(LOCAL_STORAGE_KEYS.CACHE)
            const path = `${caseId}.${flowId}.${flowInstanceId}`
            deletePropertyPath(crnCache, path)
            setToken(LOCAL_STORAGE_KEYS.CACHE, crnCache)
            //actionCodes.clearCodes(caseId, activeCodes)
            //setStep(calcNextStep)
            // In case the next step's nextButton is null, next step is the final step/thank you page.
            const ns = steps.find((st) => st.id === calcNextStep)
            const nextButton = buttonForName('nextButton', ns.buttons)

            if (!nextButton) {
              setPrevSteps([])
              // TODO we might not need to call setStep as we dispatch new layout
              setStep(calcNextStep)
              // TODO transform steps array to key value object (step id as key) in order to avoid searching
              // steps array and access step directly by its id.
              dispatch(setThankYou(ns))
            } else {
              const arr = [...prevSteps]
              arr.unshift(step)
              setPrevSteps(arr)
              setStep(calcNextStep)
              window.scrollTo(0, 0)
            }
          })
          .catch((e) => {
            finishLoading()
            addToast(
              'error',
              t(['errors.genericErrorMessage', 'options.error', ''])
            )
          })
          .finally(() => setIsLoading(false))
      } else {
        // We set the current step the first item of the prev steps queue and navigate
        // to the calculated next one.
        const arr = [...prevSteps]
        arr.unshift(step)
        setPrevSteps(arr)
        setStep(calcNextStep)
        window.scrollTo(0, 0)
      }
    }
  }

  function goBack() {
    // We retrieve the first step in the previous steps queue, remove it from queue and then
    // navigate to that step.
    //actionCodes.clearErrorCodes()
    const codesToRemove = items.map((item) => item.code)
    const { caseId } = actionCodes.getCustomerActionsByCodes(codesToRemove)

    if (codesToRemove.length > 0) {
      actionCodes.removeCodesFromCase(caseId, codesToRemove)

      codesToRemove.forEach((code) => {
        if (code in errorsRef.current) {
          delete errorsRef.current[code]
        }
        if (code in formikRef.current) {
          delete formikRef.current[code]
        }
      })
    }

    actionCodes.resetAllFields()
    const arr = [...prevSteps]
    const prevStep = arr[0]
    arr.shift()
    setPrevSteps(arr)
    setStep(prevStep)
    window.scrollTo(0, 0)
  }

  if (steps.length === 0) {
    return t(['errors.genericErrorMessage', 'options.noStepsProvided', ''])
  }

  return (
    <React.Fragment>
      {header && (
        <StyledOptionHeader
          customTheme={themeForText(header, labels)}
          dangerouslySetInnerHTML={{
            __html: actionCodes.replaceValue(caseId, header, ''),
          }}
        />
      )}
      {items.length > 0 && (
        <StyledOptionsCard>
          {backButton && (
            <StyledTopBackButton onClick={() => goBack()}>
              <BackIcon />
              <span>{backButton?.text}</span>
            </StyledTopBackButton>
          )}

          <Options
            items={items}
            randomJourneyId={randomJourneyId}
            formikRef={formikRef}
            analyticsTag={analyticsTag}
            stepId={step.id}
            caseId={actionCodes.getCustomerActions().caseId}
            flowInstanceId={actionCodes.getCustomerActions().flowInstanceId}
            flowId={actionCodes.getCustomerActions().flowId}
            errorMessages={props.errorMessages}
          />

          <ButtonsContainer>
            {backButton && (
              <StyledBackButton
                onClick={() => goBack()}
                customTheme={backButton.theme}
              >
                {backButton?.text}
              </StyledBackButton>
            )}
            {nextButton && (
              <StyledButton
                customTheme={nextButton.theme}
                className="OptionsButton"
                onClick={() => goNext(nextButton)}
                disabled={isLoading}
              >
                {isLoading ? <Loader /> : nextButton?.text}
              </StyledButton>
            )}
          </ButtonsContainer>
        </StyledOptionsCard>
      )}
      {footer && (
        <StyledOptionFooter
          customTheme={themeForText(footer, labels)}
          dangerouslySetInnerHTML={{
            __html: actionCodes.replaceValue(caseId, footer, ''),
          }}
        />
      )}
    </React.Fragment>
  )
}

export default withTranslation()(Steps)
