import * as Yup from 'yup'
import actionCodes from '../context/actionCodes'
import { validationTypes, LOCAL_STORAGE_KEYS } from '../constants'
import { getToken } from '../helpers'
import i18n from '../i18n'
import moment from 'moment'
import 'moment/locale/el'
import 'moment/locale/pt'
import 'moment/locale/en-gb'

interface ErrorMessages {
  checkboxRequired?: boolean
  fieldRequired?: boolean
  minValue?: string
  maxValue?: string
  minLength?: number
  maxLength?: number
}

/**
 * @param {*} caseId
 * @param {*} field
 * @param {*} errorMessages
 * @returns
 */
export const getValidationRules = (
  caseId: string,
  field: {
    required: boolean
    minValue: string
    maxValue: string
    type: string
    minLength: number
    maxLength: number
    regex?: string
    regexError?: string
  },
  errorMessages: ErrorMessages = {}
) => {
  let validations = []
  const {
    required,
    minValue,
    maxValue,
    type,
    minLength,
    maxLength,
    regex,
    regexError,
  } = field

  if (type === '2' && required) {
    validations.push({
      type: 'oneOf',
      params: [[true], errorMessages?.checkboxRequired || `Field is required`],
    })
  }

  if (required) {
    validations.push({
      type: 'required',
      params: [errorMessages?.fieldRequired || `Field is required`],
    })
  }

  if (minValue || minValue === '0') {
    const calc = actionCodes.replaceValue(caseId, minValue)
    validations.push({
      type: 'min',
      params: [
        calc,
        `${errorMessages?.minValue || 'Min value for this field is'} ${calc}`,
      ],
    })
  }

  if (maxValue || maxValue === '0') {
    const calc = actionCodes.replaceValue(caseId, maxValue)
    validations.push({
      type: 'max',
      params: [
        calc,
        `${errorMessages?.maxValue || 'Max value for this field is'} ${calc}`,
      ],
    })
  }

  // We not allow user to write more/less chars but just for to be sure
  if (minLength || minLength === 0) {
    const calc = actionCodes.replaceValue(caseId, minLength.toString())
    validations.push({
      type: 'min',
      params: [
        calc,
        `${errorMessages?.minLength || 'Min length for this field is'} ${calc}`,
      ],
    })
  }

  if (maxLength || maxLength === 0) {
    const calc = actionCodes.replaceValue(caseId, maxLength.toString())
    validations.push({
      type: 'max',
      params: [
        calc,
        `${errorMessages?.maxLength || 'Max length for this field is'} ${calc}`,
      ],
    })
  }

  if (regex) {
    validations.push({
      type: 'matches',
      params: [RegExp(regex), regexError ?? `The input doesn't match ${regex}`],
    })
  }

  return validations
}

/**
 *
 * @param {*} code
 * @param {*} flowId
 * @param {*} obj
 * @param {*} group
 * @param {*} caseId
 * @param {*} flowInstanceId
 * @param {*} errorMessages
 * @returns
 */
export const processForm = (
  code: string,
  flowId: string,
  obj: { initialValues: any; yupSchemeData: any },
  group: any,
  caseId: string,
  flowInstanceId: string,
  errorMessages = {}
) => {
  group.fields.forEach((field) => {
    if (field.name) {
      const codeValue = actionCodes.getValue(caseId, code, field.name)
      const cached = getToken(LOCAL_STORAGE_KEYS.CACHE)?.[caseId]?.[flowId]?.[
        flowInstanceId
      ]?.[code]?.[field.name]
      let fieldValue: string | boolean = ''
      if (codeValue === '') {
        if (field.calculated) {
          fieldValue =
            field.value !== undefined
              ? actionCodes.replaceValue(caseId, field.value)
              : ''
          if (field.type === '4') {
            fieldValue = cached ? cached : field.value
          }
        } else {
          fieldValue = cached || cached === 0 ? cached : field.value
          if (typeof fieldValue === 'string') {
            fieldValue = replaceValue(fieldValue, caseId)
          }
        }

        if (field.type === '2') {
          fieldValue = !!fieldValue
        }

        if (field.type === '5' || field.type === '6') {
          fieldValue =
            fieldValue !== '' ? formatNumber(field.round, fieldValue) : ''
        }

        if (field.type === '4' && fieldValue) {
          fieldValue = formatDate(fieldValue).toISOString()
        }

        if (field.reset) {
          actionCodes.addResetField(caseId, code, field.name)
        } else {
          actionCodes.addCode(caseId, code, field.name, fieldValue)
        }
      } else {
        fieldValue = cached ? cached : codeValue
      }

      obj.initialValues[field.name] = fieldValue

      const validationType = validationTypes[field.type]
      const validations = getValidationRules(caseId, field, errorMessages)
      obj.yupSchemeData.push({
        id: field.name,
        validationType,
        validations,
      })
    }
  })

  return obj
}

const replaceValue = (value: string, caseId: string) => {
  const regex = /[^{{]+(?=}\})/g
  const matches = value.match(regex)
  if (matches && matches.length > 0) {
    matches.forEach((m) => {
      const obj = parsePipeableExpression(m)
      const [code, field] = obj.variableName.trim().split('.')

      let codesForCase = actionCodes.getActionCodes(caseId, code)
      const codeValue = (codesForCase && codesForCase[field]) || 0
      const val = obj.format(codeValue)
      value = value.replace('{{' + m + '}}', val)
    })
  }
  try {
    // eslint-disable-next-line no-eval
    return value ? eval(value) : ''
  } catch (e) {
    return value
  }
}

type ExpressionVariableConfig = {
  variableName: string
  format?: (value: any) => any
}

export const parsePipeableExpression = (
  expression: string
): ExpressionVariableConfig => {
  const arr = expression?.split('|').map((s) => s.trim())
  if (arr.length <= 1) {
    return { variableName: expression, format: (v) => v }
  } else {
    return {
      variableName: arr[0]?.trim(),
      ...parsePipedExpressionFunction(arr[1]?.trim()),
    }
  }
}

// {{ 1.fieldName | format_currency('GBP') }} an example of expression
export const parsePipedExpressionFunction = (
  functionString: string
): Omit<ExpressionVariableConfig, 'variableName'> => {
  // get the current language as locale
  const locale = i18n?.language?.replace('_', '-') || 'en'

  if (functionString?.startsWith('format_currency')) {
    const functionName = 'format_currency'
    let currency = functionString
      .replace(functionName, '')
      .replace('(', '')
      .replace(')', '')
    currency = currency.replace(/'/g, '')
    return {
      format: (v) => {
        // formate the currency in different locale along with its symbols
        const parsedValue = parseFloat(v?.toString()) || 0
        if (currency) {
          return parsedValue?.toLocaleString(locale, {
            style: 'currency',
            currency: currency,
          })
        } else {
          console.error(
            `Received an invalid currency in the piped function expression: ${functionString}`
          )
        }
        return parsedValue?.toLocaleString(locale)
      },
    }
  } else if (functionString?.startsWith('format_date')) {
    let dateFormat = functionString
      .replace('format_date', '')
      .replace('(', '')
      .replace(')', '')
      .replace(/'/g, '')

    return {
      format: (v) => {
        if (dateFormat) {
          moment.locale(locale)
          return moment(v).format(dateFormat)
        }
        return new Date(v).toLocaleDateString(locale, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
      },
    }
  } else if (functionString === 'int') {
    return {
      format: (v) => parseInt(v),
    }
  }

  // this means function string didnt enter any conditional block above
  if (functionString) {
    console.error(
      `Received an invalid function piped in the calculated expression: ${functionString}`
    )
  }

  return {
    format: (v) => v,
  }
}

/**
 * Given a field config object we create the Yup schema needed for the form validation.
 * For each validation in validations array we create the corresponding rule and add it
 * to the yup schema based on config id per validation type.
 *
 * @param {Object} schema
 * @param {Object} config
 * @returns {Object}
 */
export const createYupSchema = (
  schema: { [x: string]: any },
  config: { id: any; validationType: any; validations?: any[] }
) => {
  const { id, validationType, validations = [] } = config
  if (!Yup[validationType]) {
    return schema
  }
  let validator = Yup[validationType]()
  validations.forEach((validation) => {
    const { params, type } = validation
    if (!validator[type]) {
      return
    }
    validator = validator[type](...params)
  })
  schema[id] = validator
  return schema
}

/**
 * Given an array of transitions and a caseId returns the next step based and transitions conditions and priority.
 * If one or more conditions are met then we return the step with the lower priority. Also we return
 * beforeTransition array that may contain one or more actions to be made before moving to the next step.
 * @param {String} caseId
 * @param {Array} transitions
 * @returns
 */
export const getNextStep = (caseId: string, transitions) => {
  if (transitions.length > 1) {
    let priority = Infinity
    let step = null
    transitions.forEach((t) => {
      if (
        (!t.condition ||
          (t.condition.length > 0 &&
            actionCodes.replaceValue(caseId, t.condition))) &&
        t.priority < priority
      ) {
        step = { calcNextStep: t.to, beforeTransition: t.beforeTransition }
        priority = t.priority
      }
    })

    return step
  } else {
    const trans = transitions[0]
    return {
      calcNextStep: trans?.to,
      beforeTransition: trans?.beforeTransition,
    }
  }
}

export const clearValue = (val) => {
  if (val) {
    const repl = val.toString().replace(/,/g, '').replace(/€ /g, '')
    return repl
  }
  return val === 0 ? 0 : ''
}

export const formatNumber = (round, value) => {
  // eslint-disable-next-line
  if (value || value == 0) {
    let fieldValue = value
    if (round) {
      fieldValue =
        fieldValue < 0
          ? Math.floor(Math.abs(fieldValue)) * -1
          : parseInt(fieldValue)
    } else {
      fieldValue =
        fieldValue < 0
          ? (Math.floor(Math.abs(fieldValue) * 100) * -1) / 100
          : parseFloat(fieldValue).toFixed(2)
    }
    return fieldValue
  }
  return value
}

export const isNumber = (n) => {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

export const formatDate = (value) => {
  const parsedDate = Date.parse(value)
  if (isNaN(parsedDate)) {
    return new Date(value.replace(/\s/, 'T'))
  } else {
    return new Date(parsedDate)
  }
}
