import React from 'react'
import { useStore } from '../../context'
import { next } from '../../context/actionReducer'
import {
  StyledCardBox,
  StyledCardBoxHeader,
  StyledCardBoxValue,
  StyledCardBoxDesc,
  StyledCardButton,
} from '../../layouts/styles'
import actionCodes from '../../context/actionCodes'
import { getToken, setToken } from '../../helpers'
import { LOCAL_STORAGE_KEYS } from '../../constants'
import { useMessages } from '../../context/messages'
import Modal, {
  ModalLoadButton,
  ModalSubtitle,
  ModalTitle,
  ClearData,
  LastModifiedLabel,
} from '../../components/Modal'
import merge from 'ts-deepmerge'
import { UIService } from '../../services'
import Loader from '../../components/Loader'
import { Button } from '../../helpers'
import moment from 'moment'
import { TFunction, withTranslation } from 'react-i18next'

interface CardBoxProps {
  header?: string
  headerTheme?: any
  value?: string
  valueTheme?: any
  desc?: string
  descTheme?: any
  height?: number
  setHeight?: React.Dispatch<React.SetStateAction<number>>
  button?: Button
  t: TFunction
}

const CardBox: React.FC<CardBoxProps> = ({
  header,
  value,
  desc,
  height,
  setHeight,
  button = null,
  headerTheme,
  valueTheme,
  descTheme,
  t,
}) => {
  const [, dispatch] = useStore()
  const headerRef = React.useRef(null)
  const extraStyle = {}
  const [showModal, setShowModal] = React.useState(false)

  const [cacheIsLoading, setCacheIsLoading] = React.useState(false)
  const [headIsLoading, setHeadIsLoading] = React.useState(false)
  const [lastModified, setLastModified] = React.useState('')
  const initStep = button?.action?.option?.steps
    ? button?.action?.option?.steps[0]?.id
    : 0
  const step = initStep || 0
  const stepItems = button?.action?.option?.steps
    ? button.action?.option?.steps.find((st) => st.id === step).items
    : []

  const { addToast } = useMessages()

  const loadCache = () => {
    setCacheIsLoading(true)
    const customerActions = actionCodes.getCustomerActions()
    UIService.getCustomerCache(
      customerActions.caseId,
      customerActions.flowId,
      customerActions.flowInstanceId
    )
      .then((res) => {
        if (res.data) {
          let crnCache = getToken(LOCAL_STORAGE_KEYS.CACHE)
          if (!crnCache) {
            crnCache = {}
          }
          // merge on correct caseId > flowId > flowInstanceId
          let crnFormCache =
            crnCache[customerActions.caseId]?.[customerActions.flowId]?.[
              customerActions.flowInstanceId
            ]
          if (!crnFormCache) {
            crnFormCache = {
              [customerActions.caseId]: {
                [customerActions.flowId]: {
                  [customerActions.flowInstanceId]: res.data,
                },
              },
            }
          } else {
            const toMerge = merge(crnFormCache, res.data)
            crnFormCache = {
              [customerActions.caseId]: {
                [customerActions.flowId]: {
                  [customerActions.flowInstanceId]: toMerge,
                },
              },
            }
          }
          setToken(LOCAL_STORAGE_KEYS.CACHE, merge(crnCache, crnFormCache))
        }
      })
      .catch((e) => {
        if (!getToken(LOCAL_STORAGE_KEYS.CACHE)) {
          addToast('error', t('cacheModal.cacheError', ''))
        }
      })
      .finally(() => {
        setCacheIsLoading(false)
        setShowModal(false)
        dispatch(next(button.action))
      })
  }

  const clearCache = () => {
    let crnCache = getToken(LOCAL_STORAGE_KEYS.CACHE)
    if (crnCache) {
      delete crnCache[button.action.caseId]
      setToken(LOCAL_STORAGE_KEYS.CACHE, crnCache)
    }

    if (stepItems && stepItems.length > 0) {
      const codesToClear = stepItems.map((item) => item.code)
      actionCodes.clearCodes(button.action.caseId, codesToClear)
    }
    const customerActions = actionCodes.getCustomerActions()
    UIService.deleteCustomerCacheForCase(
      button.action.caseId,
      customerActions.flowId,
      customerActions.flowInstanceId
    ).finally(() => {
      setShowModal(false)
      dispatch(next(button.action))
    })
  }

  const handleClick = () => {
    if (button.action.type === 'showOption') {
      setHeadIsLoading(true)
      const { caseId = '', flowInstanceId = '', flowId } = button.action
      actionCodes.addCase(caseId, flowId, flowInstanceId)
      const customerActions = actionCodes.getCustomerActions()
      UIService.headCustomerCache(
        customerActions.caseId,
        customerActions.flowId,
        customerActions.flowInstanceId
      )
        .then((res) => {
          if (res) {
            const lastModifiedString = res.headers['last-modified']
            if (res.status === 200) {
              const lastMod = moment(
                new Date(Date.parse(lastModifiedString))
              ).fromNow()
              setLastModified(lastMod)
              setShowModal(true)
            }
          }
        })
        .catch((e) => {
          if (!e.message.includes('404')) {
            addToast('error', t('cacheModal.cacheError', ''))
          }
          dispatch(next(button.action))
        })
        .finally(() => setHeadIsLoading(false))
    } else if (button.action.type === 'link') {
      const link = button.action.url
      window.open(link, '_blank').focus()
    } else {
      dispatch(next({ ...button.action }))
    }
  }

  React.useEffect(() => {
    let abortController = new AbortController()
    if (headerRef.current !== null) {
      const currHeight: number = headerRef.current.clientHeight
      if (currHeight > height) {
        setHeight(currHeight)
      }
    }
    return () => {
      abortController.abort()
    }
  })

  if (height && header) {
    extraStyle['height'] = height
  }

  return (
    <StyledCardBox>
      <Modal show={showModal}>
        <ModalTitle>{t('cacheModal.title', '')}</ModalTitle>
        <ModalSubtitle>{t('cacheModal.subtitle', '')}</ModalSubtitle>
        <div className="row">
          <LastModifiedLabel>
            Information saved {lastModified}
          </LastModifiedLabel>
          <ModalLoadButton onClick={loadCache}>
            {cacheIsLoading ? <Loader /> : t('cacheModal.loadButton', '')}
          </ModalLoadButton>
          <ClearData onClick={clearCache}>
            {t('cacheModal.clearButton', '')}
          </ClearData>
        </div>
      </Modal>
      <StyledCardBoxHeader
        className="cardbox-header"
        style={{ ...extraStyle }}
        ref={headerRef}
        dangerouslySetInnerHTML={{ __html: header }}
        customTheme={headerTheme}
      />
      {value && (
        <StyledCardBoxValue
          dangerouslySetInnerHTML={{ __html: value }}
          customTheme={valueTheme}
        />
      )}
      {desc && (
        <StyledCardBoxDesc
          className="cardbox-desc"
          dangerouslySetInnerHTML={{ __html: desc }}
          customTheme={descTheme}
        />
      )}
      {button && button.text && (
        <StyledCardButton onClick={handleClick} customTheme={button.theme}>
          {headIsLoading ? <Loader /> : button.text}
        </StyledCardButton>
      )}
    </StyledCardBox>
  )
}

export default withTranslation()(CardBox)
